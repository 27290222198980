
<template>
  <div>
    <div id="component">
      <CSBreadcrumb v-if="previousRoute.name == 'propertyData'" />
      <CSTabBar
        :tabbar="tabbar"
        :checkedTab="assessmentType"
        @changeTabBar="changeAssessmentType"
      ></CSTabBar>
      <div class="filter-panel">
        <CSSelect style="width: 185px; margin-right: 10px">
          <el-date-picker
            v-model="queryParams.startDate"
            type="date"
            style="width: 157px"
            :picker-options="{ disabledDate: (e) => elDisabledDate(e, true) }"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="请选择日期"
            prefix-icon="el-icon-time"
            :editable="false"
          ></el-date-picker>
        </CSSelect>
        <span
          style="
            display: inline-block;
            vertical-align: middle;
            height: 2px;
            width: 20px;
            margin-right: 0;
            background-color: #999;
          "
        >
        </span>
        <CSSelect style="margin-left: 10px; width: 185px">
          <el-date-picker
            v-model="queryParams.endDate"
            type="date"
            style="width: 157px"
            :picker-options="{ disabledDate: (e) => elDisabledDate(e, false) }"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="请选择日期"
            prefix-icon="el-icon-time"
            :editable="false"
          ></el-date-picker>
        </CSSelect>
        <template v-if="[1001, 1002].includes(Number(staffInfo.dutyType))">
          <CSSelect>
            <select
              v-model="queryParams.orgId"
              @change="getStaffList()"
              style="margin-left: 5px"
            >
              <option value="">全部部门</option>
              <option
                v-for="department in orgList"
                :key="department.code"
                :value="department.code"
              >
                {{ department.name }}
              </option>
            </select>
          </CSSelect>
        </template>
        <CSSelect style="width: 200px; margin-right: 30px">
          <select v-model="queryParams.userId" style="margin-left: 5px">
            <option selected value="">全部员工</option>
            <option
              v-for="staff in staffList"
              :value="staff.id"
              :key="staff.id"
            >
              {{ staff.name }}
            </option>
          </select>
        </CSSelect>
        <button class="btn btn-primary" @click="getAssessmentSummaryData()">
          查询
        </button>
      </div>

      <div class="result-panel">
        <CSTable v-show="assessmentType === 1" :thead-top="filterHeight">
          <template v-slot:thead>
            <tr>
              <th class="text-center">日期</th>
              <th class="text-center">员工</th>
              <th class="text-center">部门</th>
              <th class="text-center">岗位</th>
              <th class="text-center">扣分</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="(item, index) in businessItemData" :key="index">
              <td class="text-center">
                {{ item.date }}
              </td>
              <td class="text-center">
                {{ item.userName }}
              </td>
              <td class="text-center">
                {{ item.orgName }}
              </td>
              <td class="text-center">
                {{ item.staffName }}
              </td>
              <td class="text-center">
                <div
                  class="btn btn-link"
                  @click="
                    openBusinessItemDetailModal(item.userId, assessmentType)
                  "
                >
                  {{ item.score }}
                </div>
              </td>
            </tr>
            <tr v-if="isloading && businessItemData.length == 0">
              <td :colspan="12">
                <div style="text-align: center">暂无数据</div>
              </td>
            </tr>
          </template>
        </CSTable>
        <CSTable v-show="assessmentType === 2" :thead-top="filterHeight">
          <template v-slot:thead>
            <tr>
              <th class="text-center">日期</th>
              <th class="text-center">员工</th>
              <th class="text-center">部门</th>
              <th class="text-center">岗位</th>
              <th class="text-center">表名</th>
              <th class="text-center">得分</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="(item, index) in commonData" :key="index">
              <td class="text-center date-td">
                {{ item.date }}
              </td>
              <td class="text-center">
                {{ item.userName }}
              </td>
              <td class="text-center">
                {{ item.orgName }}
              </td>
              <td class="text-center">
                {{ item.staffName }}
              </td>
              <td class="text-center">
                {{ item.formName }}
              </td>
              <td class="text-center">
                <div
                  class="btn btn-link"
                  @click="openCommonItemModal(item.taskId, assessmentType)"
                >
                  {{ item.score }}/{{ item.totalScore }}
                </div>
              </td>
            </tr>
            <tr v-if="isloading && commonData.length == 0">
              <td :colspan="12">
                <div style="text-align: center">暂无数据</div>
              </td>
            </tr>
          </template>
          <Pagination name="Pagination" component="Pagination"></Pagination>
        </CSTable>
        <CSTable v-show="assessmentType === 3" :thead-top="filterHeight">
          <template v-slot:thead>
            <tr>
              <th class="text-center">日期</th>
              <th class="text-center">员工</th>
              <th class="text-center">部门</th>
              <th class="text-center">岗位</th>
              <th class="text-center">扣分</th>
              <th class="text-center">加分</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="(item, index) in systemData" :key="index">
              <td class="text-center">
                {{ item.date }}
              </td>
              <td class="text-center">
                {{ item.userName }}
              </td>
              <td class="text-center">
                {{ item.orgName }}
              </td>
              <td class="text-center">
                {{ item.staffName }}
              </td>
              <td class="text-center">
                <button
                  class="btn btn-link"
                  @click="openSystemItemModal(item, 2, assessmentType)"
                >
                  {{ item.deductScore }}
                </button>
              </td>
              <td class="text-center">
                <button
                  class="btn btn-link"
                  @click="openSystemItemModal(item, 1, assessmentType)"
                >
                  {{ item.awardScore }}
                </button>
              </td>
            </tr>
            <tr v-if="isloading && systemData.length == 0">
              <td :colspan="12">
                <div style="text-align: center">暂无数据</div>
              </td>
            </tr>
          </template>
        </CSTable>
      </div>
      <ViewModal name="viewModal" componentName="ViewModal"></ViewModal>
    </div>
  </div>
</template>
<script>
import ViewModal from "@/components/ViewModal.vue";
import { ORG_LEVEL, STORAGE_KEY } from "@/constant";
import CSSelect from "@/components/common/CSSelect";
import BackNavigatorBar from "@/components/common/BackNavigatorBar";
import CSTabBar from "@/components/common/CSTabBar";
import Pagination from "@/components/Pagination";
import {
  queryDepartmentUrl,
  queryDepartmentStaffUrl,
  professionalDetailsUrl,
  automationDetailsUrl,
  generalDetailsUrl,
  generalSummaryUrl,
  professionalSummaryUrl,
  queryInspectionUrl,
  queryTicketUrl,
  automationSummaryUrl,
} from "@/requestUrl";
import dayjs from "dayjs";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSTable from "@/components/common/CSTable";
// 考核类型
const ASSESSMENT_TYPE = {
  BUSINESS: 2,
  COMMON: 1,
  SYSTEM: 3,
};

export default {
  props: {},
  components: {
    CSTable,
    CSBreadcrumb,
    ViewModal,
    CSSelect,
    BackNavigatorBar,
    CSTabBar,
    Pagination,
  },
  created() {
    window.addEventListener("keydown", this.getAssessmentSummaryDataKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getAssessmentSummaryDataKeyDown);
  },
  deactivated(){
     window.removeEventListener("keydown", this.getAssessmentSummaryDataKeyDown);
  },
  data() {
    return {
      isloading:false,
      filterHeight: 0,
      assessmentType: 1,
      orgList: [],
      staffList: [],
      staffInfo: this.$vc.getCurrentStaffInfo(),
      queryParams: {
        regionId: this.$vc.getCurrentRegion().code,
        startDate: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        orgId: this.$vc.getCurrentStaffInfo().orgId,
        userId: "",
      },
      businessItemData: [],
      commonData: [],
      systemData: [],
      tabbar: {
        [ASSESSMENT_TYPE.COMMON]: "业务考核汇总",
        [ASSESSMENT_TYPE.BUSINESS]: "通用考核汇总",
        [ASSESSMENT_TYPE.SYSTEM]: "系统考核汇总",
      },
      previousRoute: {},
    };
  },
  mounted() {
    this.getOrgList();
    this.getStaffList();
    this.getAssessmentSummaryData();
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.getAssessmentSummaryData(pageNo);
    });
    this.previousRoute = JSON.parse(
      sessionStorage.getItem(STORAGE_KEY.ROUTE_HISTORY)
    )[0];
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  methods: {
    getAssessmentSummaryDataKeyDown(e) {
      if (e.keyCode == 13) {
        this.getAssessmentSummaryData();
      }
    },
    /**
     * 设置时间选择禁选区域
     * @param {String} date 时间
     * @param {Boolean} isStartDate 是否是开始时间
     * */
    elDisabledDate(date, isStartDate) {
      if (isStartDate) {
        return dayjs(date).isBefore(
          dayjs(this.queryParams.endDate).subtract(30, "day")
        );
      }
      return dayjs(date).isAfter(
        dayjs(this.queryParams.startDate).add(30, "day")
      );
    },
    getStaffList(query) {
      this.$fly
        .post(queryDepartmentStaffUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          departmentCode: this.queryParams.orgId,
          jobLevel: "",
          isOnTheJob: true,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          this.staffList = res.data.datas || res.data;
        });
    },
    getOrgList() {
      this.$fly
        .post(queryDepartmentUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          code: "",
          name: "",
          dutyType: "",
          parentCode: "",
          level: ORG_LEVEL.DEPARTMENT,
          pageNo: 1,
          pageSize: 50,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          this.orgList = res.data.datas || res.data;
        });
    },
    changeAssessmentType(type) {
        this.isloading = false;
      this.assessmentType = type;
      this.getAssessmentSummaryData();
    },
    getAssessmentSummaryData(pageNo = 1, pageSize = 10) {
      const requestMethodParam = {
        [ASSESSMENT_TYPE.COMMON]: professionalSummaryUrl,
        [ASSESSMENT_TYPE.BUSINESS]: generalSummaryUrl,
        [ASSESSMENT_TYPE.SYSTEM]: automationSummaryUrl,
      };
      this.$fly
        .post(requestMethodParam[this.assessmentType], {
          operatorId: this.$vc.getCurrentStaffInfo().id,
          ...this.queryParams,
          pageNo,
          pageSize,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }

          this.isloading = true;
          const resultMapping = {
            [ASSESSMENT_TYPE.COMMON]: "businessItemData",
            [ASSESSMENT_TYPE.BUSINESS]: "commonData",
            [ASSESSMENT_TYPE.SYSTEM]: "systemData",
          };
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              pageSize: res.data.total ? pageSize : res.data.length,
              currentPage: pageNo,
              total: res.data.total || res.data.length,
            });
          }
          this[resultMapping[this.assessmentType]] = res.data.datas || res.data;
        });
    },
    async openCommonItemModal(id, blockId) {
      this.$router.push({
        name: "assessmentGenGradeDetail",
        params: {
          id,
        },
        query: {
          blockId,
        },
      });
    },
    async openBusinessItemDetailModal(id, blockId) {
      this.$router.push({
        name: "assessmentProGradeDetail",
        params: {
          id,
        },
        query: {
          blockId,
          startDate: this.queryParams.startDate,
          endDate: this.queryParams.endDate,
        },
      });
    },
    async openSystemItemModal(item, queryType, blockId) {
      var id = item.userId;
      this.$router.push({
        name: "assessmentAutoGradeDetail",
        query: {
          blockId,
          operatorId: this.$vc.getCurrentStaffInfo().id,
          startDate: this.queryParams.startDate,
          endDate: this.queryParams.endDate,
          regionId: this.$vc.getCurrentRegion().communityId,
        },
        params: {
          id,
          queryType,
        },
      });
    },
  },
};
</script>
<style>
</style>
